import React, { Component } from 'react';
import axios from 'axios'
import moment from 'moment'
import { Card, Table, Button, Row, Col } from 'react-bootstrap'
import Modal from "../../components/modal"
import AdminIpHistory from './adminIpHistory';
class AdminViews extends Component {
    state = {
        views: [],
        updateCount: 0
    }
    openBotSelect = (ip) => {
        this.setState({ showIpBot: true, botSelectedToCheck: ip })
    }
    closeBotSelect = () => {
        this.setState({ showIpBot: false })
    }
    setBot = () => {
        axios.post("/api/stats/set/ip/bot", { ip: this.state.botSelectedToCheck })
        this.closeBotSelect()
    }
    componentDidMount() {
        this.getData()
    }
    componentDidUpdate=(props)=>{
        if(props.updateCount != this.props.updateCount){
            this.getData()
        }
    }
    getData = async () => {
        let data = {}
        await axios.get('/api/stats/views/get/all').then((resp) => {
            data.views = resp.data.views
            data.viewCount = resp.data.viewCount
            data.updateCount = this.props.updateCount

        })
        this.setState(data)
    }
    showIpHistory=()=>{
        this.closeBotSelect()
        this.setState({showIpHistory: true})

    }
    closeIpHistory=()=>{
        this.setState({showIpHistory: false})
    }

    render() {
        let views = []

        this.state.views && this.state.views.forEach((view, index) => {
            views.push(
                <tr onClick={() => this.openBotSelect(view.ip)} key={index}>
                    <td>{moment.unix(view.date).format('DD-MM-YYYY@HH:mm')}</td>
                    <td>{view.site}</td>
                    <td>{view.referer}</td>
                    <td >{view.ip}</td>
                </tr>
            )
        })
        return (
            <Card style={{ margin: '30px', maxWidth: "100%", overflow: "scroll" }}>
                <AdminIpHistory darkmode={this.props.darkmode} botSelectedToCheck={this.state.botSelectedToCheck} showIpHistory={this.state.showIpHistory} closeIpHistory={this.closeIpHistory}/>
                <Modal  show={this.state.showIpBot} title={<h1>{this.state.botSelectedToCheck}</h1>} content={
                    <div style={{  width: "100vw" }}>
                        <Row style={{margin: "20px"}} >
                            <button style={{margin: "auto", width: "50%"}} className="button"  onClick={this.showIpHistory}>Show Ip History</button>

                        </Row>
                        <Row style={{margin: "20px"}} >
                            <button style={{margin: "auto", width: "50%"}} className="button"  onClick={this.setBot}>Set bot</button>

                        </Row>
                    </div>



                } close={this.closeBotSelect} />

                <Card.Header className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    Views: {this.state.viewCount}
                </Card.Header>
                <p>{this.state.updateCount}</p>
                <div style={{ 'margin': '0px' }}>
                    <Table style={{ 'margin': '0px' }} bordered>
                        <thead className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                            <tr>
                                <th>Date</th>
                                <th>Page</th>
                                <th>Referer</th>
                                <th>IP</th>
                            </tr>
                        </thead>
                        <tbody className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                            {views}
                        </tbody>
                    </Table>
                </div>
                <Card.Footer className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>
        );
    }
}

export default AdminViews;