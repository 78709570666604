import React, { Component } from 'react';
import Axios from "axios"
import { Spinner, DropdownButton, DropdownItem, Row, Col, Card, Form } from 'react-bootstrap'
import moment from 'moment'
import { connect } from 'react-redux';

class AdminDomains extends Component {
    state = { 
        domains: []
     } 
    componentDidMount(){
        Axios.get("/api/domains/get/all").then(res=>{
            if(res.data.success){
                this.setState({domains: res.data.domains})
            }
        })
    }
   genDomain= (ownedDomain)=>{

    return <div style={{ margin: "20px" }}>
            <Row>
                <Col style={{ maxWidth: "200px" }}>Domain:</Col>
                <Col >
                    {ownedDomain.domain}
                </Col>
            </Row>
            <Row>
                <Col style={{ maxWidth: "200px" }}>
                Owner:
                </Col>
                <Col>
                {ownedDomain.owner}
                </Col>
            </Row>

            <Row>
                <Col style={{ maxWidth: "200px" }}>Registration Status:</Col>
                <Col >
                    {ownedDomain.status}
                </Col></Row>
            <Row>
                <Col style={{ maxWidth: "200px" }}>Registration End Date:</Col>

                <Col >
                    {moment.unix(ownedDomain.endDate).format('DD-MM-YYYY@HH:mm:ss')}

                </Col>
            </Row>
            <Row>
                <Col style={{ maxWidth: "200px" }}>Registration Price:</Col>
                <Col >
                    £{ownedDomain.price}
                </Col>
            </Row>
            <Row>
                <Col style={{ maxWidth: "200px" }}>Auto Renew:</Col>
                <Col >
                    {ownedDomain.renewalType == "none" ? "Disabled" : "Enabled"}
                </Col>
            </Row>
            <Row>
                <Col style={{ maxWidth: "200px" }}>Owner:</Col>
                <Col >
                    {ownedDomain.customerId}
                </Col>
            </Row>
 
  

            <Row style={{ margin: "20px" }}>
                <Col >
                    <button disabled={true} style={{ background: "red", width: "100%" }} className="button">Cancel</button>
                </Col>
            </Row>
        </div>
    
   }
    render() { 
        let darkmode = this.props.user.darkmode

        let domainshtml = []
        this.state.domains.forEach(domain=>{
            domainshtml.push(this.genDomain(domain))
        })
        return (
            <div>
                 <Card style={{ margin: '30px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center', backgroundColor: "#343a40" }}>
                        <h4 style={{ color: "white" }}>Domains: {this.state.domains.length}</h4>
                    </Card.Header>
                    <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ maxWidth: '100%' }} >
{domainshtml}
</div></Card>
            </div>
        );
    }
}
 
 
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminDomains);





