import React, { Component } from 'react';
import { Spinner, Row, Col, Card, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Axios from "axios"
import moment from 'moment'
import Modal from "../modal"
import StripeContainerSetupAuto from '../stripe/stripeContainerSetupAuto'
import { connect } from 'react-redux';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DNSRecords from '../myHosting/dns/dnsRecords';
class MyDomains extends Component {
    state = {
        ownedDomains: [],
        showDNSRecordsModal: false,
        dnsRecordsViewDomain: "",
        ownedSearching: true,
        cancelAutoRenewDisabled: false,
        EnableAutoRenewDisabled: false,
        showEnableAuto: false,
        showCancelAuto: false,
        autoEnableYearlyDollar: null,
        yearlyPricePound: null,
        autoEnableId: null,
        autoSetupDisabled: false,
        dollarToPound: null,
        autoCancelId: null,
        prices: []

    }
    componentDidMount() {
        this.setExchangeRate()
        this.interval1 = setInterval(() => {
            Axios.get("/api/domains/get/all/by/customer").then(res => {
                res.data.domains.sort(function (a, b) {
                    if (a._id < b._id) {
                        return -1;
                    }
                    if (a._id > b._id) {
                        return 1;
                    }
                    return 0;
                });
                let changed = false
                if (res.data.domains.length != this.state.ownedDomains.length) {
                    changed = true
                }

                this.setState({ ownedDomains: res.data.domains, ownedSearching: false })
                if (changed) {
                    let doms = []
                    for (const dom of res.data.domains) {
                        doms.push(dom.domain)
                    }

                    Axios.post("/api/domains/get/tld/prices", { domains: doms }).then(res => {
                        this.setState({ prices: res.data.price })

                    })
                }
            })
        }, 1000)
    }

    setExchangeRate = async () => {
        Axios.get("/api/domains/get/exchange-rate").then(res => {
            let rate = parseFloat(res.data.dollarToPound).toFixed(2)
            this.setState({ dollarToPound: rate })
        })
    }
    interval1 = null
    closeManageDNSRecords = () => {
        this.setState({ showDNSRecordsModal: false, dnsRecordsViewDomain: "" })
    }
    closeEnableAutoRenew = () => {
        this.setState({ showEnableAuto: false })
    }
    closeCancelAutoRenew = () => {
        this.setState({ showCancelAuto: false })
    }
    showDNSRecordsModal = (domain) => {
        this.setState({ showDNSRecordsModal: true, dnsRecordsViewDomain: domain })
    }
    cancelDomainRenewal = () => {
        this.setState({ cancelAutoRenewDisabled: true })
        Axios.post("/api/domains/cancel/autorenewal", { id: this.state.autoCancelId }).then(res => {
            this.setState({ cancelAutoRenewDisabled: false, autoCancelId: null, showCancelAuto: false })
        })
    }
    enableDomainRenewal = (id, domain) => {
        let domains = [domain]

        Axios.post("/api/domains/get/tld/prices", { domains: domains }).then(res => {
            this.setState({ showEnableAuto: true, autoEnableId: id, autoEnableYearlyDollar: parseFloat(res.data.price[0].RegistrationPrice).toFixed(2), autoSetupDisabled: false })
        })
    }
    autoEnableClicked = () => {
        this.setState({ autoSetupDisabled: true })
    }
    showCancelRenewal = (id) => {
        this.setState({ showCancelAuto: true, autoCancelId: id })

    }
    componentWillUnmount() {
        if (this.interval1) {
            clearInterval(this.interval1)
        }

    }
    render() {
        const renderTooltip1 = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                <div>

                    * Domain Prices are set in USD by the corresponding TLD authority. BreezeLabs converts this to GBP to charge the user, therefore the yearly price for each domain will differ depending on the USD - GBP exchange rate.

                </div>
            </Tooltip>
        );
        let darkmode = this.props.user.darkmode
        let myDomains = []
        let enableAutoRenewContents = <div>
            <Row>
                <Col>Yearly Price:  £{(this.state.autoEnableYearlyDollar * this.state.dollarToPound).toFixed(2)} (${this.state.autoEnableYearlyDollar})                                    </Col>
            </Row>

            <hr />


            <Row>
                <Col style={{ margin: "auto", textAlign: "center" }}>
                    <StripeContainerSetupAuto disabled={this.state.autoSetupDisabled} autoEnableClicked={this.autoEnableClicked} close={this.closeEnableAutoRenew} yearlyPricePound={(this.state.autoEnableYearlyDollar * this.state.dollarToPound).toFixed(2)} yearlyPriceDollar={this.state.autoEnableYearlyDollar} id={this.state.autoEnableId} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>* Domain Prices are set in USD by the corresponding TLD authority. BreezeLabs converts this to GBP to charge the user, therefore the yearly price for each domain will differ depending on the USD - GBP exchange rate.</p>
                    <p>* By Clicking Confirm, you are agreeing to have your default payment method automaticaly charged the renewal fee.</p>
                </Col>
            </Row>

        </div>
        let cancelAutoRenewContents = <div style={{ margin: "auto", textAlign: "center" }}>
            Are you sure you want to cancel auto renewal for your domain?
            <button style={{ background: "red", margin: "auto", width: "200px" }} onClick={this.cancelDomainRenewal} className="button">Disable Auto-Renew</button>
        </div>
        this.state.ownedDomains.forEach((ownedDomain, i) => {
            let price = null
            let domaintldsplt = ownedDomain.domain.split(".")
            let domaintld = ""
            let i2 = 0
            for (const d of domaintldsplt) {

                if (i2 > 0) {
                    if (i2 > 1) {
                        domaintld = domaintld + "."
                    }
                    domaintld = domaintld + d
                }
                i2 = i2 + 1
            }


            for (const tldprice of this.state.prices) {
                if (tldprice.Name == domaintld) {
                    price = tldprice.RegistrationPrice
                    break
                }
            }
            myDomains.push(
                <div style={{ margin: "20px" }}>
                    <Row>
                        <Col style={{ maxWidth: "200px" }}>Domain:</Col>
                        <Col >
                            {ownedDomain.domain}
                        </Col>
                    </Row>

                    <Row>
                        <Col style={{ maxWidth: "200px" }}>Registration Status:</Col>
                        <Col >
                            {ownedDomain.status}
                        </Col></Row>
                    <Row>
                        <Col style={{ maxWidth: "200px" }}>Registration End Date:</Col>

                        <Col >
                            {moment.unix(ownedDomain.endDate).format('DD-MM-YYYY@HH:mm:ss')}

                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ maxWidth: "200px" }}>Registration Price:</Col>

                        <Col >
                            £{ownedDomain.price} ${price}<OverlayTrigger
                                placement="left"
                                delay={{ show: 250, hide: 400 }}
                                overlay={renderTooltip1}
                            >
                                <FontAwesomeIcon style={{ color: "white", margin: "auto", paddingLeft: "5px" }} size='1x' icon={faQuestionCircle} />
                            </OverlayTrigger>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ maxWidth: "200px" }}>Auto Renew:</Col>
                        <Col >
                            {ownedDomain.renewalType == "none" ? "Disabled" : "Enabled"}
                        </Col>
                    </Row>

                    <Row style={{ margin: "20px" }}>
                        <Col >
                            <Button style={{ width: "100%" }} onClick={() => this.showDNSRecordsModal(ownedDomain.domain)} >Manage DNS Records</Button>
                        </Col>
                    </Row>

                    <Row style={{ margin: "20px" }}>
                        <Col >
                            {ownedDomain.renewalType != "none" ?
                                <button disabled={this.state.cancelAutoRenewDisabled} onClick={() => { this.showCancelRenewal(ownedDomain._id) }} style={{ background: "red", width: "100%" }} className="button">Cancel Renewal</button>
                                : <button disabled={this.state.EnableAutoRenewDisabled} onClick={() => { this.enableDomainRenewal(ownedDomain._id, ownedDomain.domain) }} style={{ background: "green", width: "100%" }} className="button">Enable Renewal</button>
                            }
                        </Col>
                    </Row>
                </div>
            )
            if (i != this.state.ownedDomains.length - 1) {
                myDomains.push(<hr></hr>)
            }
        })
        let mydoms = null
        if (myDomains[0]) {
            mydoms = myDomains
        } else if (!this.state.ownedSearching) {
            mydoms = <div style={{ margin: "20px" }}>
                <Row style={{ margin: "auto" }}>
                    <Col style={{ margin: "auto", textAlign: "center" }}>You dont own any domains yet. Buy one above.</Col>
                </Row>
            </div>
        }
        return (<div>
            <Card style={{ margin: '30px' }}>
                <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    <h4 >My Domains</h4>
                </Card.Header>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} >
                    <div style={{ maxWidth: '100%' }} >
                        {mydoms}
                    </div>
                    {this.state.ownedSearching && <div>
                        <Row>
                            <Col style={{ margin: "auto", textAlign: "center", margin: "20px" }}>
                                <Spinner animation="border" vaiant="secondary" style={{ width: '50px', height: '50px', fontSize: '30px' }} />
                            </Col>

                        </Row>
                        <Row>
                            <Col style={{ margin: "auto", textAlign: "center", margin: "20px" }}>
                                <p>Loading Your Domains...</p>

                            </Col>

                        </Row></div>}
                </div>
                <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>
            <Modal close={this.closeManageDNSRecords} show={this.state.showDNSRecordsModal}  title={<h1>Manage DNS Records</h1>} content={<DNSRecords domain={this.state.dnsRecordsViewDomain}/>} />

            <Modal width="1000px" show={this.state.showEnableAuto} title={<h1>Enable autorenew</h1>} content={enableAutoRenewContents} close={this.closeEnableAutoRenew} />
            <Modal width="1000px" show={this.state.showCancelAuto} title={<h1>Cancel autorenew</h1>} content={cancelAutoRenewContents} close={this.closeCancelAutoRenew} />

        </div>);
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setUser: (data) => { dispatch(setUser(data)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyDomains);
