import React, { Component } from 'react';
import { Card, Table, Form, Button, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import { connect } from 'react-redux';

class AdminBalance extends Component {
    state = {
        available: "",
        pending: "",
        total: "",
        updateCount: 0
    }
    componentDidUpdate=(props)=>{
        if(props.updateCount != this.props.updateCount){
            this.setState({updateCount: this.props.updateCount})
            this.getData()
        }
    }
    componentDidMount() {
      this.getData()
    }
    getData=()=>{
        axios.get("/api/stats/admin/get/balance").then(res => {
            this.setState(res.data)
        })
    }
    render() {
        let darkmode = this.props.user.darkmode

        return (
            <div style={{}}>
                <Card style={{ margin: '30px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                        Balance
                    </Card.Header>
                    {this.state.updateCount}
                    <div className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                        <Row><Col><p>
                            Total: {this.state.total}
                        </p></Col></Row>
                        <Row><Col>
                            <p>
                                Pending: {this.state.pending}
                            </p></Col>
                        </Row>
                        <Row><Col>
                            <p style={{ paddingBottom: "8px" }}>
                                Available: {this.state.available}
                            </p></Col></Row>
                        <br />
                        <br />
                        <br />
                    </div>
                    <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                    </Card.Footer>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setUser: (data) => { dispatch(setUser(data)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminBalance);
