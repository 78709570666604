import {Route, Redirect} from "react-router-dom"
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Axios from 'axios'
class PublicRoute extends Component {
    state = {  }
    render() {
        Axios.post('api/stats/page-visit', {referer: document.referrer ? document.referrer : 'searchbar', site: window.location.href})
        window.gtag_report_conversion_page_view()

        if(!this.props.user){
            return (
                <Route exact={this.props.exact} path={this.props.path}> {this.props.children} </Route>  
            )
        }else{
            //pretty much used for page refreshes
            let redirectTo = this.props.location.state ? this.props.location.state.last : 'my-hosting'
            return (
                <Redirect to={redirectTo}/>
            )
        }
    }
}
function mapStateToProps(state){
    return{
        user: state.user
    }
  }
export default connect(mapStateToProps)(PublicRoute);
