import React, { Component } from 'react';
import { Button, Modal, Form, Row, Col, DropdownItem, DropdownButton } from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons'
class AddDNSRecord extends Component {
    state = {
        ttl: 300,
        value: "",
        type: null,
        name: ""
    }

    setType = (type) => {
        this.setState({ type: type })
    }
    setValue = (e) => {
        this.setState({ value: e.target.value })
    }
    setName = (e) => {
        this.setState({ name: e.target.value })
    }
    setTtl = (e) => {
        this.setState({ ttl: e.target.value })
    }
    addRecord = () => {
        this.setState({ ttl: 300, value: "", type: null })
        let name = this.state.name ? this.state.name + "." + this.props.domain : this.props.domain
        Axios.post("/api/sites/add/dns/record", { name: name,ttl: this.state.ttl, value: this.state.value, type: this.state.type, domain: this.props.domain }).then(res => {
            this.props.closeAddDNSRecordModal()

        })
    }

    render() {
        let darkmode = this.props.user.darkmode
        let ttlvalid = this.state.ttl
        let typevalid = this.state.type
        let valuevalid = this.state.value
        let addenabled = ttlvalid && typevalid && valuevalid
        return (
            <Modal onHide={this.props.closeAddDNSRecordModal} show={this.props.show}>
                <Modal.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                    <Modal.Title>
                        Add DNS Record
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                    <Row style={{ marginRight: "10px", paddingLeft: "10px" }}>
                        <Col>Record Name</Col>
                        <Col><input style={{height: "10px"}} value={this.state.name} onChange={e => { this.setName(e) }} type="text"></input></Col>
                        {<p>{"."+this.props.domain}</p>}
                        
                        <p style={{fontSize:'12px', padding: "6px 0"}}><FontAwesomeIcon  size='1x' icon={faInfoCircle}/> Leave record name blank if unsure.</p>

                    </Row>
                    <Row style={{ marginRight: "10px", paddingLeft: "10px" }}>
                        <Col>Record Name</Col>
                        <Col>Type:</Col>
                        <DropdownButton align="end" title={this.state.type ? this.state.type : "Type"} variant="primary">
                            <DropdownItem onClick={() => this.setType("A")}>A</DropdownItem>
                            <DropdownItem onClick={() => this.setType("AAAA")}>AAAA</DropdownItem>
                            <DropdownItem onClick={() => this.setType("CAA")}>CAA</DropdownItem>
                            <DropdownItem onClick={() => this.setType("CNAME")}>CNAME</DropdownItem>
                            <DropdownItem onClick={() => this.setType("DS")}>DS</DropdownItem>
                            <DropdownItem onClick={() => this.setType("MX")}>MX</DropdownItem>
                            <DropdownItem onClick={() => this.setType("NAPTR")}>NAPTR</DropdownItem>
                            <DropdownItem onClick={() => this.setType("NS")}>NS</DropdownItem>
                            <DropdownItem onClick={() => this.setType("PTR")}>PTR</DropdownItem>
                            <DropdownItem onClick={() => this.setType("SOA")}>SOA</DropdownItem>
                            <DropdownItem onClick={() => this.setType("SPF")}>SPF</DropdownItem>
                            <DropdownItem onClick={() => this.setType("SRV")}>SRV</DropdownItem>
                            <DropdownItem onClick={() => this.setType("TXT")}>TXT</DropdownItem>
                        </DropdownButton>
                    </Row>
                    <Row style={{ marginRight: "10px", paddingLeft: "10px" }}>
                        <Col>Value:</Col>
                        <input value={this.state.value} onChange={e => { this.setValue(e) }} type="text"></input>
                    </Row>
                    <Row style={{ marginRight: "10px", paddingLeft: "10px" }}>
                        <Col>TTL (Seconds):</Col>
                        <input value={this.state.ttl} onChange={e => { this.setTtl(e) }} type="number"></input>

                    </Row>
                </Modal.Body>
                <Modal.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                    <Button onClick={this.props.closeAddDNSRecordModal} className='button-warning' style={{ marginBottom: '10px', width: "100px" }}>Back</Button>

                    <Button disabled={!addenabled} onClick={this.addRecord} className='button-primary' style={{ marginBottom: '10px', width: "100px" }}>Add</Button>

                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDNSRecord);
