import React, { Component } from 'react';
import { Button, Form, Row, Col, Card, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux';
import axios from "axios"
class AdminLog extends Component {
    state = {  } 
    emptyFile=(file)=>{
        axios.post("/api/logs/clear/by/name", {file: file}).then(res=>{
            this.props.getData()
        })
    }
    render() { 
        let darkmode = this.props.user.darkmode

        return (

            <Card style={{ margin: '30px' }}>
            <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
            {this.props.file}
            </Card.Header>
            <Button style={{width: '100%'}}  type='submit' onClick={()=>this.emptyFile(this.props.file)}>Empty file</Button>
            <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{textAlign: "center"}}>
                {this.props.loading == false?
                                <pre style={{ whiteSpace: 'pre-wrap' , textAlign: "left", color: "white"}}>

                                {this.props.data}
                </pre>
                :
                <div><Spinner animation="border" vaiant="secondary" style={{width: '40px', height:'40px', fontSize: '10px'}} /> <div>Loading</div></div>

            }

            </div>
            <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
            </Card.Footer>
        </Card>
       
        );
    }
}
 
function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLog);






