import React, { Component } from 'react';
import { Card, Table, Form, Button, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import { connect } from 'react-redux';
class AdminCounts extends Component {
    state = { usersCount: 0, updateCount: 0, gross: 0, subscriptions: 0 }
    getData() {
        axios.post('/api/user/get/total/count', { showAdmin: this.state.includeAdmin }).then((res) => {
            this.setState({ usersCount: res.data.userCount })
        })

        axios.get('/api/stats/gross/get/count').then((resp) => {
            this.setState({ gross: resp.data.current })
        })
        axios.get('/api/stats/subscriptions/get/count').then((resp) => {
            this.setState({ subscriptions: resp.data.current })
        })



    }
    componentDidUpdate = (props) => {
        if (props.updateCount != this.props.updateCount) {
            this.setState({ updateCount: this.props.updateCount })
            this.getData()
        }
    }
    render() {
        let darkmode = this.props.user.darkmode
        return (
            <div>
                <Card style={{ margin: '30px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                        Stats
                    </Card.Header>
                    {this.state.updateCount}
                    <div className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                        <Row>
                            <Col>
                                <p>
                                    PageViews: {this.state.pageviews}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Users: {this.state.usersCount}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Subscriptions: {this.state.subscriptions}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Sites: {this.state.sites}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p>
                                    Gross: £{this.state.gross}
                                </p>
                            </Col>
                        </Row>

                    </div>
                    <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                    </Card.Footer>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setUser: (data) => { dispatch(setUser(data)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCounts);
