import React, { Component } from 'react';
import { Button, Modal, Form, Row, Col, DropdownItem, DropdownButton } from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
class RemoveDNSRecord extends Component {
    state = {
    }

    handleRecordDelete=()=>{
        Axios.post("/api/sites/remove/dns/record", {name: this.props.record.name,ttl: this.props.record.ttl_seconds, value: this.props.record.value, type: this.props.record.type, domain: this.props.domain}).then(res=>{
            this.props.closeRemoveDNSRecordModal()
        })
    }

    render() {
        let darkmode = this.props.user.darkmode
        return (
            <Modal onHide={this.props.closeRemoveDNSRecordModal} show={this.props.show}>
                <Modal.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                    <Modal.Title>
                        Remove DNS Record
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                    <Row style={{ marginRight: "10px", paddingLeft: "10px" }}>
                        Are you sure you want to delete this record?
                    </Row>
                    <Row style={{ marginRight: "10px", paddingLeft: "10px" }}>

                        <Button onClick={this.handleRecordDelete} className='button-warning' style={{ marginBottom: '10px', width: "100px" }}>Remove</Button>

                    </Row>
                </Modal.Body>
                <Modal.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"}>
                    <Button onClick={this.props.closeRemoveDNSRecordModal} className='button-primary' style={{ marginBottom: '10px', width: "100px" }}>Cancel</Button>


                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RemoveDNSRecord);
