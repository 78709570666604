import React, { Component } from 'react';
import { Button, Form, Row, Col, Card, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux';
import axios from "axios"
import AdminLog from './adminLog';

class AdminLogs extends Component {
    state = {
        logs: {},
        loading: false,
        first: true,
        lastUpdated: 0,

    }
    componentDidMount(){
        this.getData()
        let interval1 = setInterval(() => this.getData(), 20000)
        let interval2 = setInterval(() => {
            this.setState({ lastUpdated: this.state.lastUpdated + 1 })
        }, 1000)
        this.setState({ "interval1": interval1, "interval2": interval2 })
    }
    componentWillUnmount = () => {
        if (this.state.interval1) {
            clearInterval(this.state.interval1)
        }
        if (this.state.interval2) {
            clearInterval(this.state.interval2)
        }
    }
    getData = async()=>{
        if(this.state.first){
            this.setState({loading: true})
        }
        axios.get("/api/logs/get/all").then(res=>{
            this.setState({logs: res.data.data, loading: false, lastUpdated: 0, first: false})
        })

    }

    render() {
        let darkmode = this.props.user.darkmode

        return (<div>
<p>Last updated: {this.state.lastUpdated}</p>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_server_out"]} file="hosting-server-out" file_under="hosting_server_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_server_error"]} file="hosting-server-error" file_under="hosting_server_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["aws_domain_reccomendation_error"]} file="aws-domain-reccomendation-error" file_under="aws_domain_reccomendation_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["aws_domain_reccomendation_out"]} file="aws-domain-reccomendation-out" file_under="aws_domain_reccomendation_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["breezelabs_server_out"]} file="breezelabs-server-out" file_under="breezelabs_server_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["breezelabs_server_error"]} file="breezelabs-server-error" file_under="breezelabs_server_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["f1standingscalculator_com_server_out"]} file="f1standingscalculator-com-server-out" file_under="f1standingscalculator_com_server_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["f1standingscalculator_com_server_error"]} file="f1standingscalculator-com-server-error" file_under="f1standingscalculator_com_server_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_aws_domain_operation_check_out"]} file="hosting-aws-domain-operation-check-out" file_under="hosting_aws_domain_operation_check_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_aws_domain_operation_check_error"]} file="hosting-aws-domain-operation-check-error" file_under="hosting_aws_domain_operation_check_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_backup_python_out"]} file="hosting-backup-python-out" file_under="hosting_backup_python_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_backup_python_error"]} file="hosting-backup-python-error" file_under="hosting_backup_python_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_cert_renew_out"]} file="hosting-cert-renew-out" file_under="hosting_cert_renew_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_cert_renew_error"]} file="hosting-cert-renew-error" file_under="hosting_cert_renew_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_certsite_out"]} file="hosting-certsite-out" file_under="hosting_certsite_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_certsite_error"]} file="hosting-certsite-error" file_under="hosting_certsite_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_domain_payment_intent_expiry_out"]} file="hosting-domain-payment-intent-expiry-out" file_under="hosting_domain_payment_intent_expiry_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_domain_payment_intent_expiry_error"]} file="hosting-domain-payment-intent-expiry-error" file_under="hosting_domain_payment_intent_expiry_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_email_handler_expiry_out"]} file="hosting-email-handler-out" file_under="hosting_email_handler_expiry_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_email_handler_expiry_error"]} file="hosting-email-handler-error" file_under="hosting_email_handler_expiry_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_stats_gross_create_daily_out"]} file="hosting-stats-gross-create-daily-out" file_under="hosting_stats_gross_create_daily_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_stats_gross_create_daily_error"]} file="hosting-stats-gross-create-daily-error" file_under="hosting_stats_gross_create_daily_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_stats_subscriptions_create_daily_out"]} file="hosting-stats-subscriptions-create-daily-out" file_under="hosting_stats_subscriptions_create_daily_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_stats_subscriptions_create_daily_error"]} file="hosting-stats-subscriptions-create-daily-error" file_under="hosting_stats_subscriptions_create_daily_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_stats_users_create_daily_out"]} file="hosting-stats-users-create-daily-out" file_under="hosting_stats_users_create_daily_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_stats_users_create_daily_error"]} file="hosting-stats-users-create-daily-error" file_under="hosting_stats_users_create_daily_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_subscription_check_out"]} file="hosting-subscription-check-out" file_under="hosting_subscription_check_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_subscription_check_error"]} file="hosting-subscription-check-error" file_under="hosting_subscription_check_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_subscription_storage_check_out"]} file="hosting-subscription-storage-check-out" file_under="hosting_subscription_storage_check_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["hosting_subscription_storage_check_error"]} file="hosting-subscription-storage-check-error" file_under="hosting_subscription_storage_check_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["mongo_hosting_backup_out"]} file="mongo-hosting-backup-out" file_under="mongo_hosting_backup_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["mongo_hosting_backup_error"]} file="mongo-hosting-backup-error" file_under="mongo_hosting_backup_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["printing_server_out"]} file="printing-server-out" file_under="printing_server_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["printing_server_error"]} file="printing-server-error" file_under="printing_server_error"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["robotconsole_server_out"]} file="robotconsole-server-out" file_under="robotconsole_server_out"/>
            <AdminLog getData={this.getData} loading={this.state.loading} data={this.state.logs["robotconsole_server_error"]} file="robotconsole-server-error" file_under="robotconsole_server_error"/>
        </div>);
    }
}

function mapStateToProps(state) {
    return {
        user: state.user,
    }
}
function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogs);






