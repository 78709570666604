import React, { Component } from 'react';
import { connect } from 'react-redux';

class BasketUpSell extends Component {
    state = {  } 
    render() { 
        return (
            <div></div>
        );
    }
}
 
function mapStateToProps(state) {
    return {
        basket: state.basket,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BasketUpSell);