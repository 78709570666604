import React, { Component } from 'react';
import { Card, Table, Form, Button, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import chart from 'chart.js'
import moment from 'moment'

class AdminTotalStatsGraph extends Component {
    state = {
        updateCount: 0
    }
    totalStatsChartRef = React.createRef()

    componentDidMount() {
        this.getTotalGraphData()
    }
    genTotalGraph = (viewGraphData) => {
        const chartFromRef = this.totalStatsChartRef.current.getContext('2d')
        if (this.chartInstance) {

            const hiddenDatasets = [];
            this.chartInstance.data.datasets.forEach((dataset, index) => {
                for (const key in dataset._meta) {
                    if (dataset._meta[key].hidden == true) {
                        viewGraphData.datasets[index].hidden = true

                    }
                }

            });


            this.chartInstance.data = viewGraphData
            this.chartInstance.options.animation = false;

            this.chartInstance.update();


        } else {
            this.chartInstance = new chart(chartFromRef, {
                type: 'line',
                data: viewGraphData,
                options: {
                    scales: {
                        xAxes: [{
                            gridLines: { color: "gray" },
                            ticks: {
                                fontColor: 'white'
                            }
                        }],
                        yAxes: [{
                            gridLines: { color: "gray" },
                            ticks: {
                                fontColor: 'white'
                            }
                        }],

                    }
                }
            })
        }


    }
    componentDidUpdate=(props)=>{
        if(props.updateCount != this.props.updateCount){
            this.getTotalGraphData()
        }
    }
    getTotalGraphData = async () => {
        this.setState({updateCount: this.props.updateCount})
        let formattedDates = []
        for (let index = 0; index < 34; index++) {
            formattedDates[33 - index] = moment().startOf('day').subtract(index, 'days').format('DD/MM')
        }
        let graphdata = {
            'labels': formattedDates,
            'datasets': []
        }
        await new Promise((res, rej) => {
            axios.get('/api/stats/subscriptions/get/count').then((resp) => {
                let data = []
                for (const stat of resp.data.prev) {
                    data.push(stat.nonAdminCount)
                }
                data = data.reverse()
                data[33] = resp.data.current
                graphdata.datasets.push({
                    'label': 'Subscriptions',
                    'data': data,
                    "borderColor": "blue",
                    "backgroundColor": 'rgba(255, 255, 255, 0.5)'
                })
                res()
            })
        })
        await new Promise((res, rej) => {
            axios.get('/api/stats/users/get/count').then((resp) => {
                let data = []
                for (const stat of resp.data.prev) {
                    data.push(stat.nonAdminCount)
                }
                data = data.reverse()
                data[33] = resp.data.current
                graphdata.datasets.push({
                    'label': 'Users',
                    'data': data,
                    "borderColor": "green",
                    "backgroundColor": 'rgba(255, 255, 255, 0.5)'
                })
                res()
            })
        })
        await new Promise((res, rej) => {
            axios.get('/api/stats/gross/get/count').then((resp) => {
                let data = []
                for (const stat of resp.data.prev) {
                    data.push(stat.nonAdminCount)
                }
                data = data.reverse()
                data[33] = resp.data.current
                graphdata.datasets.push({
                    'label': 'Gross',
                    'data': data,
                    "borderColor": "yellow",
                    "backgroundColor": 'rgba(255, 255, 255, 0.5)',
                })
                res()
            })
        })
        this.genTotalGraph(graphdata)

    }
    render() {
        return (
            <Row>
                <Col>

                    <Card className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ margin: '30px' }}>
                        <Card.Header className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                            Total Stats
                        </Card.Header>
                        <p>{this.state.updateCount}</p>
                        <canvas style={{ maxHeight: '500px', maxWidth: '1000px', margin: 'auto', color: "white" }} id='linechart' ref={this.totalStatsChartRef} />
                    </Card>
                </Col>
            </Row>
        );
    }
}

export default AdminTotalStatsGraph;