import React, { Component } from 'react';
import { Card, Button, Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import Axios from 'axios';
import { connect } from 'react-redux';
import { Link, Redirect } from "react-router-dom"
import CancelPlan from '../components/plans/cancelPlan'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faQuestionCircle, faStopCircle } from '@fortawesome/free-solid-svg-icons'

import RestartPlan from '../components/plans/restartPlan'
import { setSubscriptionsCancelled } from '../actions/subscriptionsActions'
import DeletePlan from '../components/plans/deletePlan'
import { setSubscriptionsData, startSetSubscriptionsData } from '../actions/subscriptionsActions'
import { setSubscriptionsDetailedIndex } from "../actions/subscriptionsActions"

class ManagePlan extends Component {
    state = {
        plan: null,
        site: false,
        domain: null,
        type: null,
        showCancelModal: false,
        showDeletePlanModal: false,
        showRestartPlanModal: false,
        sslCertified: false,
        backup: null,
        invoice: null
    }

    componentDidMount() {
        if (this.props.subscriptions.detailedIndex !== null) {
            this.getSiteData()

        } else {

            this.refreshPlans()
        }


    }
    refreshPlans = async () => {
        this.props.startSetSubscriptionsData()
        await this.props.setSubscriptionsData(this.props.user.email)




    }
    getSiteData = () => {
        if (!this.props.subscriptions.detailedIndex) {
            return
        }
        let subid = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionItem
        Axios.post('/api/sites/get-site', { subId: subid }).then(req => {
            if (req.data.site) {
                this.setState({ site: req.data.site, domain: req.data.domain, type: req.data.type, sslCertified: req.data.sslCertified, backup: req.data.backup })
            } else {
                this.setState({ site: req.data.site, domain: null, type: null, backup: req.data.backup })
            }
        })
        this.getInvoice()
    }

    cancelSubscription = () => {
        this.setState({ showCancelModal: true })
    }
    closeCancelModal = () => {
        this.setState({ showCancelModal: false })
    }

    planCancelled = () => {
        this.props.setSubscriptionsCancelled(this.props.subscriptions.detailedIndex)
        this.setState({ showCancelModal: false })
    }

    openDeletePlanModal = () => {
        this.setState({ showDeletePlanModal: true })
    }
    closeDeletePlanModal = () => {
        this.setState({ showDeletePlanModal: false })
    }
    openRestartPlanModal = () => {
        this.setState({ showRestartPlanModal: true })
    }
    closeRestartPlanModal = () => {
        this.setState({ showRestartPlanModal: false })
    }
    getInvoice = () => {
        Axios.post('/api/user/subscription/invoice', { subId: this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex].subscriptionId }).then(req => {
            this.setState({ invoice: req.data.invoice })
        })
    }

    render() {
        let darkmode = this.props.user.darkmode
        if(!this.props.subscriptions.setSubscriptionsDetailedIndex && this.props.subscriptions.subscriptions[0]){
            const urlParams = new URLSearchParams(window.location.search);
            const subscription_url_param = urlParams.get('site');
            let counter_site = 0
            for (const subs of this.props.subscriptions.subscriptions) {
                if(subs._id == subscription_url_param){
                    this.props.setSubscriptionsDetailedIndex(counter_site)
                    break
                }
                counter_site = counter_site + 1
            }
        }


        let subscription = this.props.subscriptions.subscriptions[this.props.subscriptions.detailedIndex]
        if (!subscription) {
            return <div> Cannot Find Site</div>
        }
        // if (this.props.subscriptions.detailedIndex === null) {
        //     return <Redirect to='/my-hosting' />
        // }
        const renderTooltip = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                <div>
                    Green Tick = Backup Date Set
                </div>
                <div>
                    Orange Square = Default Backup Date
                </div>
            </Tooltip>
        );
        const renderTooltip1 = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                <div>
                    Green Tick = Website Installed
                </div>
                <hr />
                <div>
                    Red Square = No Website Installed (Go To The Website Tab To Install - We Recommend WordPress For Most Cases)
                </div>
            </Tooltip>
        );
        const renderTooltip0 = (props) => (
            <Tooltip id="button-tooltip" {...props}>
                <div>
                    Green Tick = Domain Name Set
                </div>
                <div>
                    Red Square = No Domain Set (go to the DNS tab to set)
                </div>
            </Tooltip>
        );

        return (
            <div>
                <Card style={{ width: '80%', margin: 'auto', display: 'block', marginTop: '60px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                        Get Started
                    </Card.Header>
                    <Card.Text className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingBottom: '10px', paddingTop: "10px", paddingLeft: '20px', paddingRight: '20px' }}>
                        <Row >
                            <Col>
                                <Row>
                                    {this.state.domain ? <FontAwesomeIcon style={{ paddingBottom: '20px', color: "green", margin: "auto" }} size='4x' icon={faCheckCircle} /> :
                                        <FontAwesomeIcon style={{ paddingBottom: '20px', color: "red", margin: "auto" }} size='4x' icon={faStopCircle} />}
                                </Row>
                                <Row >
                                    <p style={{ margin: "auto" }}>
                                        <Link to="/my-hosting/manage-plan/dns"> Set Domain Name</Link>

                                    </p>
                                </Row>
                                <Row>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip0}
                                    >
                                        <FontAwesomeIcon style={{ paddingBottom: '20px', color: "red", margin: "auto" }} size='2x' icon={faQuestionCircle} />
                                    </OverlayTrigger>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    {this.state.type && this.state.type != "parking" ? <FontAwesomeIcon style={{ paddingBottom: '20px', color: "green", margin: "auto" }} size='4x' icon={faCheckCircle} /> :
                                        <FontAwesomeIcon style={{ paddingBottom: '20px', color: "red", margin: "auto" }} size='4x' icon={faStopCircle} />}
                                </Row>
                                <Row>
                                    <p style={{ margin: "auto" }}>

                                        <Link to="/my-hosting/manage-plan/website"> Install Website</Link>  </p>
                                </Row>
                                <Row>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip1}
                                    >
                                        <FontAwesomeIcon style={{ paddingBottom: '20px', color: "red", margin: "auto" }} size='2x' icon={faQuestionCircle} />
                                    </OverlayTrigger>
                                </Row>

                            </Col>
                            <Col>
                                <Row>
                                    {this.state.backup === "sunday 23:59" ? <FontAwesomeIcon style={{ paddingBottom: '20px', color: "orange", margin: "auto" }} size='4x' icon={faCheckCircle} /> :
                                        <FontAwesomeIcon style={{ paddingBottom: '20px', color: "green", margin: "auto" }} size='4x' icon={faCheckCircle} />}
                                </Row>

                                <Row>
                                    <p style={{ margin: "auto" }}>

                                        <Link to="/my-hosting/manage-plan/backups"> Set Backup Date</Link>
                                    </p>
                                </Row>
                                <Row>
                                    <OverlayTrigger
                                        placement="right"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={renderTooltip}
                                    >
                                        <FontAwesomeIcon style={{ paddingBottom: '20px', color: "red", margin: "auto" }} size='2x' icon={faQuestionCircle} />
                                    </OverlayTrigger>
                                </Row>
                            </Col>

                        </Row>
                    </Card.Text>

                </Card>
                <Card style={{ width: '80%', margin: 'auto', display: 'block', marginTop: '60px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                        Hosting Plan Details
                    </Card.Header>
                    <Card.Text className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingBottom: '10px', paddingTop: "10px" }}>
                        <p>Plan: {subscription.plan}</p>
                        <p>Next bill: {subscription.endDate}</p>
                        <p>Status: {subscription.status}</p>
                        {subscription.status === 'waiting' &&
                            <div>
                                <p style={{ maxWidth: '600px', 'margin': 'auto', textAlign: 'center' }}>If your status has been "waiting" for a while click the "Change Payment" button below to change your payment method or pay now.</p>
                                <div style={{ paddingBottom: '40px', paddingTop: '10px' }}><Button href={this.state.invoice} style={{ backgroundColor: '#343a40' }}>Change Payment</Button></div></div>
                        }
                        {subscription.status !== 'waiting' &&
                            <div><Button href={this.state.invoice} target="_blank" style={{ width: "80%" }}>Latest Invoice</Button></div>
                        }
                        {subscription.status !== 'cancelled' &&
                            <div style={{ marginTop: "10px" }}><Button onClick={this.cancelSubscription} style={{ backgroundColor: 'red', width: "80%" }}>Cancel Subscription</Button></div>}
                        {subscription.status === 'cancelled' &&
                            <div style={{ marginTop: "10px" }}><Button onClick={this.openRestartPlanModal} style={{ backgroundColor: '#343a40' }}>Restart Subscription</Button></div>
                        }
                    </Card.Text>

                </Card>

                <Card style={{ width: '80%', margin: 'auto', display: 'block', marginTop: '20px' }}>
                    <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                        Delete Plan
                    </Card.Header>
                    <Card.Text className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingTop: "10px" }}>
                        <Button onClick={() => { this.openDeletePlanModal() }} style={{ backgroundColor: 'red', marginBottom: '10px', width: "80%" }}>Delete Plan</Button>
                    </Card.Text>
                </Card>
                <CancelPlan closeCancelModal={this.closeCancelModal} cancelled={this.planCancelled} show={this.state.showCancelModal} />

                <DeletePlan closeDeletePlanModal={this.closeDeletePlanModal} show={this.state.showDeletePlanModal} />
                <RestartPlan closeRestartPlanModal={this.closeRestartPlanModal} show={this.state.showRestartPlanModal} />

            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setSubscriptionsCancelled: (index) => { dispatch(setSubscriptionsCancelled(index)) },
        startSetSubscriptionsData: () => { dispatch(startSetSubscriptionsData()) },
        setSubscriptionsData: (data) => { dispatch(setSubscriptionsData(data)) },
        setSubscriptionsDetailedIndex: (data) => { dispatch(setSubscriptionsDetailedIndex(data)) }

    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ManagePlan);

