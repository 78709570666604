import React, { Component } from 'react';
import AddDNSRecord from '../../../components/plans/addDNSRecord'
import RemoveDNSRecord from '../../../components/plans/removeDNSRecord'
import { Card, Button, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux';
import Axios from 'axios';

class DNSRecords extends Component {
    state = {
        dnsRecords: [],
        domain: ""
    }
    componentDidMount() {
        if (this.props.subscriptions.detailedIndex !== null) {
            this.getSiteData()

        }


    }
    getSiteData = () => {
        Axios.post('/api/sites/get-dns', { domain: this.props.domain }).then(req => {
            if (req.data.site) {
                this.setState({ dnsRecords: req.data.dnsRecords[0] ? req.data.dnsRecords : [], domain: this.props.domain })
            } else {
                this.setState({ domain: null })
            }
        })
    }
    closeAddDNSRecordModal = () => {
        this.getSiteData()
        this.setState({ showAddRecord: false })
    }
    closeRemoveDNSRecordModal = () => {
        this.getSiteData()
        this.setState({ removeDnsRecord: false, removeDNSRecordObj: null })
    }

    openConfirmDeleteRecord = (record) => {
        this.setState({ removeDNSRecordObj: record, removeDnsRecord: true })
    }
 
    render() {
        let darkmode = this.props.user.darkmode
        if(this.state.domain != this.props.domain){
            this.getSiteData()
        }
        let dnsRecordsHtml = []
        this.state.dnsRecords.forEach((record, i) => {
            dnsRecordsHtml.push(
                <div key={"dns_record" + i}>
                    <Row style={{ marginRight: "10px", paddingLeft: "10px" }}>
                    <Col>Name:</Col>
                    <Col>{record.name}</Col>
                        <Col>Type:</Col>
                        <Col>{record.type}</Col>
                        <Col>Value:</Col>
                        <Col>{record.value}</Col>
                        <Col>TTL:</Col>
                        <Col>{record.ttl_seconds}</Col>
                        <Col>                <Button onClick={() => this.openConfirmDeleteRecord(record)} className='button-warning' style={{ marginBottom: '10px', width: "100px" }}>Delete</Button>
                        </Col>
                    </Row>
                </div>

            )
        })
        return (
            <div>
                <div className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ textAlign: 'center', paddingTop: "10px" }}>
                    <Button onClick={() => { this.setState({ showAddRecord: true }) }} className='button-primary' style={{ marginBottom: '10px', width: "50%" }}>Add Record</Button>
                    {dnsRecordsHtml}
                </div>
                <AddDNSRecord domain={ this.props.domain} closeAddDNSRecordModal={this.closeAddDNSRecordModal} show={this.state.showAddRecord} />
                <RemoveDNSRecord domain={ this.props.domain} record={this.state.removeDNSRecordObj} closeRemoveDNSRecordModal={this.closeRemoveDNSRecordModal} show={this.state.removeDnsRecord} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.subscriptions,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        setSubscriptionsCancelled: (index) => { dispatch(setSubscriptionsCancelled(index)) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DNSRecords);