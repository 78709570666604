import React, { Component } from 'react';
import { Card, Table, Button, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import moment from 'moment'

class AdminSites extends Component {
    state = { 
        sites: [],
        updateCount: 0
     } 
     componentDidUpdate=(props)=>{
        if(props.updateCount != this.props.updateCount){
            this.setState({updateCount: this.props.updateCount})
            this.getData()
        }
    }
    getData=async()=>{
        await axios.get('/api/sites/get/all').then((sites) => {
            let data = {}

            data.sites = sites.data.sites
            this.setState(data)
        })
    }
    render() { 
        let sites = []
        this.state.sites.forEach(site => {
            sites.push(
                <tr key={site._id}>
                    <td>{site.domain}</td>
                    <td>{site.type}</td>
                    <td>{site.owner}</td>
                    <td>{site.sslCertified}</td>
                </tr>
            )
        })
        return (
            <Card style={{ margin: '30px', maxWidth:"100%", overflow: "scroll" }}>
            <Card.Header className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                Sites: {this.state.sites.length}
            </Card.Header>
            {this.state.updateCount}
            <div style={{ 'margin': '0px' }} >
                <Table bordered style={{ 'margin': '0px' }}>
                    <thead className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                        <tr>
                            <th>Domain</th>
                            <th>Type</th>
                            <th>Owner</th>
                            <th>SSL</th>
                        </tr>
                    </thead>
                    <tbody className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                        {sites}
                    </tbody>
                </Table>
            </div>
            <Card.Footer className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
            </Card.Footer>
        </Card>
        );
    }
}
 
export default AdminSites;