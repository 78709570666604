import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Button, Form, DropdownButton, DropdownItem } from 'react-bootstrap'
import StripeContainerBasket from "../components/stripe/stripeContainerBasket"
import axios from "axios"
import { removeItem, setContactIndex, addBasketItem } from '../actions/basketActions'
import Modal from "../components/modal"
import BasketUpSell from "../components/basketUpSell"

import RegistrationContacts from '../components/domain/registrationContacts';
import Suggestions from "./suggestions"
class Basket extends Component {
    state = {
        prices: [],
        dollarToPound: null,
        showContacts: false,
        techContactModifying: {},
        registrationContactModifying: {},
        adminContactModifying: {},
        contactActive: 0,
        stage1Done: false,
        stage2Done: false



    }
    componentDidMount() {
        this.setExchangeRate()
    }
    getPrices = (prices) => {
        axios.post("/api/basket/get/prices", { prices: prices }).then(res => {
            let upd = false
            let newp = this.state.prices
            for (const price of res.data.prices) {
                let found = false
                for (const stat of this.state.prices) {
                    if (stat.price == price.price) {
                        found = true
                    }
                }
                if (!found) {
                    newp.push({ price: price.price, act: price.actPrice })
                    upd = true
                }
            }
            if (upd) {
                this.setState({ prices: newp })
            }
        })


    }
    closeContacts = () => {
        this.props.setContactIndex(this.state.contactActive)
        this.setState({ showContacts: false })
    }
    showContacts = () => {
        this.setState({ showContacts: true })
    }
    setExchangeRate = async () => {
        axios.get("/api/domains/get/exchange-rate").then(res => {
            let rate = parseFloat(res.data.dollarToPound).toFixed(2)
            this.setState({ dollarToPound: rate })
        })
    }
    setContacts = (stuff, valid) => {
        let item = this.props.basket[this.state.contactActive]
        delete item.active
        item.contactsAndPrivacy = stuff
        item.techContactValid = stuff.techContactValid
        item.registrationContactValid = stuff.registrationContactValid
        item.adminContactValid = stuff.adminContactValid
        delete item.contactsAndPrivacy.registrationContactValid
        delete item.contactsAndPrivacy.techContactValid
        delete item.contactsAndPrivacy.adminContactValid
        this.props.addBasketItem(item)
        this.setState({ contactActive: 0 })
        this.closeContacts()
    }
    updateRenew = (i, autorenew) => {
        let item = this.props.basket[i]
        item.autoRenew = autorenew
        this.props.addBasketItem(item)

    }
    changeDomYears = (years, item) => {
        let upItem = item
        upItem.years = years
        this.props.addBasketItem(upItem)
    }
    changeAmount = (amount, item) => {
        if (!amount.target.value) {
            amount.target.value = 1
        }
        let upItem = item
        upItem.amount = amount.target.value
        upItem.amountOverwrite = true
        this.props.addBasketItem(upItem)
    }

    render() {
        let darkmode = this.props.user.darkmode

        let basket = this.props.basket
        let numberofitems = 0
        let basketItemsHtml = []
        let totalCost = 0
        let prices = []
        let buyNowEnabled = basket[0] ? true : false
        basket.forEach((basketItem, i) => {
            numberofitems += parseInt(basketItem.amount)
            if (basketItem.type == "hosting") {
                if (!prices.includes(basketItem.priceId)) {
                    prices.push(basketItem.priceId)
                }
                let price = 0.0
                this.state.prices.forEach(pr => {
                    if (pr.price == basketItem.priceId) {
                        totalCost += parseFloat(basketItem.amount) * parseFloat(pr.act)
                        price = pr.act
                    }
                })
                basketItemsHtml.push(
                    <div >
                        <div style={{ margin: "20px", width: "100%", textAlign: "center", maxWidth: "100%" }}>
                            <Row>

                                <Col>
                                    <h3>{basketItem.name} Hosting</h3>
                                </Col>
                            </Row>

                            <Row style={{ maxWidth: "100%" }}>
                                <Col>
                                    Price {"£" + price}
                                </Col>
                                <Col>
                                    <Row>
                                        <Col>
                                            Amount:</Col>
                                        <Col> <input type="number" onChange={(e) => this.changeAmount(e, basketItem)} value={basketItem.amount}></input></Col>


                                    </Row>
                                </Col>
                                <Col>
                                    <button style={{ background: "red", width: "100px", margin: "auto" }} role='link' onClick={() => this.props.removeItem({ type: "hosting", price: basketItem.priceId })} className="button">Remove</button>

                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }
            if (basketItem.type == "domain") {
                let pounds = parseFloat((basketItem.priceDollarPerYear * this.state.dollarToPound * basketItem.years).toFixed(2))
                if (basketItem.offer && basketItem.years == 1) {
                    totalCost += parseFloat(basketItem.offer)
                } else {
                    totalCost += parseFloat(pounds)
                }
                let domval = basketItem.techContactValid && basketItem.registrationContactValid && basketItem.adminContactValid
                buyNowEnabled = buyNowEnabled && domval

                basketItemsHtml.push(
                    <div>
                        <div style={{ margin: "20px", width: "100%", textAlign: "center", maxWidth: "100%", margin: "auto" }}>
                            <Row>
                                <Col>
                                    <h3>Domain: {basketItem.domain}</h3>
                                </Col>
                            </Row>
                            {/* <hr className={darkmode ? "darkmode-hr" : "lightmode-hr"} style={{ width: "60%" }} /> */}

                            <Row style={{ margin: "auto", textAlign: "center", marginBottom: "20px" }}>
                                <Col >

                                    <DropdownButton style={{ width: "200px", margin: "auto" }} title={basketItem.years.toString() + " Years"} variant="primary">
                                        <DropdownItem onClick={() => this.changeDomYears(1, basketItem)}>1 Year</DropdownItem>
                                        <DropdownItem onClick={() => this.changeDomYears(2, basketItem)}>2 Years</DropdownItem>
                                        <DropdownItem onClick={() => this.changeDomYears(3, basketItem)}>3 Years</DropdownItem>
                                        <DropdownItem onClick={() => this.changeDomYears(4, basketItem)}>4 Years</DropdownItem>
                                        <DropdownItem onClick={() => this.changeDomYears(5, basketItem)}>5 Years</DropdownItem>
                                        <DropdownItem onClick={() => this.changeDomYears(6, basketItem)}>6 Years</DropdownItem>
                                        <DropdownItem onClick={() => this.changeDomYears(7, basketItem)}>7 Years</DropdownItem>
                                        <DropdownItem onClick={() => this.changeDomYears(8, basketItem)}>8 Years</DropdownItem>
                                        <DropdownItem onClick={() => this.changeDomYears(9, basketItem)}>9 Years</DropdownItem>
                                        <DropdownItem onClick={() => this.changeDomYears(10, basketItem)}>10 Years</DropdownItem>
                                    </DropdownButton>

                                </Col>
                            </Row>
                            <Row style={{ maxWidth: "100%", margin: "auto" }}>

                                <Col>


                                    {basketItem.offer && basketItem.years == 1 ?
                                        <div>
                                            <Row>
                                                <Col>
                                                    Price: £{basketItem.offer}
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <p style={{ fontSize: "10px" }}>For the first year, then: £{pounds} (${basketItem.priceDollarPerYear * basketItem.years}) per year</p>
                                                </Col>
                                            </Row>
                                        </div>
                                        :
                                        <div>                                Price: £{pounds} (${basketItem.priceDollarPerYear * basketItem.years})                    </div>
                                    }






                                </Col>

                            </Row>


                            <Row style={{ maxWidth: "800px", margin: "auto", textAlign: "center" }}>
                                <Col style={{ margin: "auto", textAlign: "center" }}>
                                    <div style={{ margin: "auto", textAlign: "center", margin: "20px", }}>
                                        <button style={{ background: domval ? "rgb(52, 58, 64)" : "red", width: "200px", margin: "auto", textAlign: "center" }} role='link' onClick={
                                            () => {
                                                this.showContacts(); this.setState(
                                                    {
                                                        registrationContactModifying: basketItem.contactsAndPrivacy.RegistrationContact,
                                                        techContactModifying: basketItem.contactsAndPrivacy.TechContact,
                                                        adminContactModifying: basketItem.contactsAndPrivacy.AdminContact,
                                                        contactActive: i

                                                    }); this.props.setContactIndex(i)
                                            }
                                        } className="button">Update Contacts</button>
                                    </div>

                                </Col>

                                <Col style={{ margin: "auto", textAlign: "center" }}>
                                    <div style={{ margin: "auto", textAlign: "center", margin: "20px", }}>

                                        {basketItem.autoRenew ?
                                            <button style={{ background: "rgb(52, 58, 64)", width: "200px", margin: "auto", textAlign: "center" }} role='link' onClick={() => this.updateRenew(i, false)} className="button">Disable AutoRenew</button>

                                            :
                                            <button style={{ background: "rgb(52, 58, 64)", width: "200px", margin: "auto", textAlign: "center" }} role='link' onClick={() => this.updateRenew(i, true)} className="button">Enable AutoRenew</button>

                                        }</div></Col>


                                <Col>
                                    <div style={{ margin: "auto", textAlign: "center", margin: "20px", }}>

                                        <button style={{ background: "red", width: "100px", margin: "auto", textAlign: "center" }} role='link' onClick={() => this.props.removeItem({ type: "domain", domain: basketItem.domain })} className="button">Remove</button>
                                    </div>
                                </Col>
                            </Row>
                            {
                                !domval
                                &&
                                <Row style={{ margin: "auto", textAlign: "center" }}>
                                    <Col >
                                        <p style={{ color: "red", textAlign: "center", margin: "auto" }}><b>Registration Contacts Not Valid, Click "Update Contacts" to provide valid domain registration contacts!</b></p>
                                    </Col></Row>
                            }
                        </div>
                    </div>

                )
            }
            if (i < basket.length - 1) {
                basketItemsHtml.push(<div ><hr className={darkmode ? "darkmode-hr" : "lightmode-hr"} style={{ width: "70%" }} /> </div>)
            }
        })
        this.getPrices(prices)
        let error = null
        if (!buyNowEnabled && numberofitems > 0) {
            error = <p style={{ color: "red" }}>One of your basket items is not valid, please correct it!</p>
        }
        if (!buyNowEnabled && numberofitems <= 0) {
            error = <p style={{ color: "red" }}>Your basket is empty</p>
        }
        let checkoutstu = []
        this.props.basket.forEach(item => {
            let html
            if (item.type == "hosting") {
                if (!prices.includes(item.priceId)) {
                    prices.push(item.priceId)
                }
                let price = 0.0
                this.state.prices.forEach(pr => {
                    if (pr.price == item.priceId) {
                        totalCost += parseFloat(item.amount) * parseFloat(pr.act)
                        price = pr.act
                    }
                })
                html =<div> <p>{item.name + " Hosting" + " x " + item.amount}</p>  <p>Price {"£" + (price * item.amount) }</p> <hr className={darkmode ? "darkmode-hr" : "lightmode-hr"} style={{ width: "70%" }} /></div>
            } else if (item.type == "domain") {
                html = <p>{"Domain: " + item.domain + " x " + item.years + " years"}</p>
                let pounds = parseFloat((item.priceDollarPerYear * this.state.dollarToPound * item.years).toFixed(2))
                let price
                if (item.offer && item.years == 1) {
                    price = <div>
                        <Row>
                            <Col>
                                Price: £{item.offer}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <p style={{ fontSize: "10px" }}>For the first year, then: £{pounds} (${item.priceDollarPerYear * item.years}) per year</p>
                            </Col>
                        </Row>
                        <hr className={darkmode ? "darkmode-hr" : "lightmode-hr"} style={{ width: "70%" }} />
                    </div>
                } else {
                    price = <div>Price: £{pounds} (${item.priceDollarPerYear * item.years})                                        <hr className={darkmode ? "darkmode-hr" : "lightmode-hr"} style={{ width: "70%" }} /></div>
                }
                html = <div>{html}{price}</div>

            }

            checkoutstu.push(html)
        })
        return (
            <div>
                {/* Basket manage */}
                <div style={{ width: "100%", margin: "auto", maxWidth: "2000px" }} >

                    <Card style={{ width: "80%", margin: "auto", textAlign: "center" }} >
                        <Card.Header className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                            <b>Basket: {numberofitems} items </b>
                        </Card.Header>

                        <div style={{ position: "relative", overflow: "hidden" }} className={darkmode ? "darkmode-card-content" : "lightmode-card-content"} >
                            <div style={{ position: "relative", width: "100%", display: "flex", flexDirection: "row" }} className={this.state.stage1Done ? 'basket-slide-offscreen' : ''}>

                                <div style={{ flex: '1 0 100%' } } >
                                    <div style={this.state.hidestage1 ? {height: "20px"}: {height: "100%"}}>
                                    {basketItemsHtml.length == 0 &&
                                        <h3 style={{ marginTop: "20px" }}>
                                            Your Basket Is Empty
                                        </h3>
                                    }
                                    {basketItemsHtml}
                                    <Suggestions />

                                    <div style={{}}>
                                        <hr className={darkmode ? "darkmode-hr" : "lightmode-hr"} style={{ width: "70%" }} />
                                        <Row>
                                            <Col>
                                                <p style={{ textAlign: "center" }}>Total to pay now: £{totalCost.toFixed(2)}</p>
                                            </Col>
                                        </Row>
                                    </div></div>
                                </div>
                                <div style={{ flex: '1 0 100%', textAlign: "center" }}>
             

                                    <Row style={{marginTop: "20px"}}><Col><h3>Items</h3></Col></Row>
                                    <hr className={darkmode ? "darkmode-hr" : "lightmode-hr"} style={{ width: "70%" }} />

                                    {checkoutstu}

                                    <Row style={{marginTop: "20px"}}><Col><h4>Total: £{totalCost.toFixed(2)}</h4></Col></Row>
                                    <hr className={darkmode ? "darkmode-hr" : "lightmode-hr"} style={{ width: "70%" }} />

                                    {/* <h3>Enhance Your Experience With...</h3> */}
                                    <BasketUpSell />
                                    <button style={{ background: "red", width: "200px", margin: "20px", textAlign: "center", margin: "auto", marginBottom: "20px" }} onClick={() => this.setState({ stage1Done: false, hidestage1: false })} className="button"> Edit Basket</button>
                                </div>






                            </div>







                        </div>
                        <Card.Footer className={darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: "center", margin: "auto", width: "100%" }}>
                            {!this.state.stage1Done && <button disabled={!buyNowEnabled || numberofitems > 40} style={{ background: "green", width: "200px", margin: "20px", textAlign: "center", margin: "auto" }} onClick={() => this.setState({ stage1Done: true, hidestage1: true })} className="button"> Continue To Checkout</button>}
                            {this.state.stage1Done && <StripeContainerBasket disabled={!buyNowEnabled || numberofitems > 40} />}
                            {error}
                        </Card.Footer>
                    </Card>
                    <Modal show={this.state.showContacts} title={<h1>Contacts</h1>} content={
                        <RegistrationContacts techContactPrefill={this.state.techContactModifying} registrationContactPrefill={this.state.registrationContactModifying} adminContactPrefill={this.state.adminContactModifying} setContacts={this.setContacts} />

                    } close={this.closeContacts} />

                </div>



            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        basket: state.basket,
        user: state.user
    }
}
function mapDispatchToProps(dispatch) {
    return {
        removeItem: (data) => { dispatch(removeItem(data)) },
        setContactIndex: (data) => { dispatch(setContactIndex(data)) },
        addBasketItem: (data) => { dispatch(addBasketItem(data)) }


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Basket);
