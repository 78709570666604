import React, { Component } from 'react';
import AdminUserView from "./adminUserView"
import { Card, Table, Form } from 'react-bootstrap'
import axios from "axios"
import AdminUserDetails from './adminUserDetails';

class AdminUserList extends Component {
    state = {
        usersCount: 0,
        users: [],
        includeAdmin: true,
        showUserDetails: false,
        userSelectedToCheck: null,
        updateCount: 0
    }
    componentDidMount = () => {
        this.getData()
    }
    componentDidUpdate = (props) => {
        if (props.updateCount != this.props.updateCount) {
            this.setState({ updateCount: this.props.updateCount })
            this.getData()
        }
    }
    getData = () => {
        axios.post('/api/user/get/latest', { showAdmin: this.state.includeAdmin }).then((res) => {
            this.setState({ users: res.data.user })
        })
        axios.post('/api/user/get/total/count', { showAdmin: this.state.includeAdmin }).then((res) => {
            this.setState({ usersCount: res.data.userCount })
        })
    }
    updateIncludeAdmin = () => {
        this.setState({ includeAdmin: !this.state.includeAdmin }, () => {
            this.getData()
        })
    }
    showUserDetails = (user) => {
        this.setState({ showUserDetails: true, userSelectedToCheck: user })
    }
    closeUserDetails = () => {
        this.setState({ showUserDetails: false })
    }
    render() {
        let users = []
        this.state.users.forEach((user) => {
            users.push(

                <AdminUserView openUserDetails={() => this.showUserDetails(user._id)} key={user._id} user={user} />)

        })
        return (<div>


            <Card style={{ margin: '30px', maxWidth: "100%", overflow: "scroll" }}>
                <AdminUserDetails darkmode={this.props.darkmode} userSelectedToCheck={this.state.userSelectedToCheck} showUserDetails={this.state.showUserDetails} closeUserDetails={this.closeUserDetails} />

                <Card.Header className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    Users: {this.state.usersCount}
                </Card.Header>
                {this.state.updateCount}
                <div className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"} style={{ maxWidth: '100%' }}>
                    Include Admins?
                    <Form.Control readOnly={true} checked={this.state.includeAdmin} onClick={() => this.updateIncludeAdmin("usersIncludeAdmin")} type="checkbox"></Form.Control>

                    <Table bordered>
                        <thead className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                            <tr>
                                <th>Email</th>
                                <th>Email Coms</th>
                                <th>Text Coms</th>
                                <th>Phone Coms</th>
                                <th>Admin</th>
                                <th>Date Created</th>
                            </tr>
                        </thead>
                        <tbody className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                            {users}
                        </tbody>
                    </Table>
                </div>
                <Card.Footer className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>

        </div>);
    }
}

export default AdminUserList;