import React, { Component } from 'react';
import { Card, Table, Button, Row, Col } from 'react-bootstrap'
import axios from 'axios'
import { toInteger } from 'lodash';
import moment from 'moment'

class AdminSubscriptions extends Component {
    state = {
        subscriptions: [],
        updateCount: 0
    }
    componentDidMount(){
        this.getData()
    }
    componentDidUpdate=(props)=>{
        if(props.updateCount != this.props.updateCount){
            this.setState({updateCount: this.props.updateCount})
            this.getData()
        }
    }
    getStart = (obj) => {
        let timestamp = obj._id.toString().substring(0, 8)
        let date = new Date(parseInt(timestamp, 16) * 1000)
        let format = moment(date).format('DD-MM-YYYY:HH:mm:ss')
        return format
    }
    getData=async()=>{
        let data = {}

        await axios.get('/api/payment/get/all').then((respo) => {
            data.subscriptions = respo.data.subscriptions
            let money = 0
            for (let sub of data.subscriptions) {
                if (sub.customerId != "thomas.breeze@icloud.com") {
                    let spl = sub.priceId.split("£")[1]
                    money = money + toInteger(spl)
                }
            }
            data.nonAdminGross = money
            this.setState(data)

        })
    }

    render() {
        let subscriptions = []
        this.state.subscriptions.forEach(subscription => {
            subscriptions.push(
                <tr key={subscription._id}>
                    <td>{subscription.customerId}</td>
                    <td>{subscription.productId}</td>
                    <td>{subscription.priceId}</td>
                    <td>{subscription.status}</td>
                    <td style={{ background: subscription.endDate < moment().unix() && "red" }}>{moment.unix(subscription.endDate).format('DD-MM-YYYY@HH:mm')}</td>
                    <td>{this.getStart(subscription)}</td>
                </tr>
            )
        })

        return (
            <Card style={{ margin: '30px', maxWidth:"100%", overflow: "scroll" }}>
                <Card.Header className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} style={{ textAlign: 'center' }}>
                    Subscriptions: {this.state.subscriptions.length}   |    Gross(non admin): £{this.state.nonAdminGross}
                </Card.Header>
                {this.state.updateCount}
                <div style={{ 'margin': '0px',  maxWidth:"100%" }} >
                    <Table bordered style={{ 'margin': '0px', maxWidth:"100%" }}>
                        <thead style={{maxWidth:"100%"}} className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                            <tr>
                                <th>User</th>
                                <th>Product</th>
                                <th>Price</th>
                                <th>Status</th>
                                <th>End Date</th>
                                <th>Start Date</th>

                            </tr>
                        </thead>
                        <tbody style={{maxWidth:"100%"}} className={this.props.darkmode ? "darkmode-card-content" : "lightmode-card-content"}>
                            {subscriptions}
                        </tbody>
                    </Table>
                </div>
                <Card.Footer className={this.props.darkmode ? "darkmode-card-header" : "lightmode-card-header"} >
                </Card.Footer>
            </Card>
        );
    }
}

export default AdminSubscriptions;